import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink, Events, scrollSpy } from 'react-scroll';

import '../index.css';

const Home = () => {
	const apiUrl = process.env.REACT_APP_API_URL;
	const [activeSection, setActiveSection] = useState('');

	useEffect(() => {
		const handleSetActive = (to) => {
			setActiveSection(to);
		};

		Events.scrollEvent.register('begin', handleSetActive);
		Events.scrollEvent.register('end', handleSetActive);

		scrollSpy.update();

		return () => {
			Events.scrollEvent.remove('begin', handleSetActive);
			Events.scrollEvent.remove('end', handleSetActive);
		};
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			const sections = document.querySelectorAll('section');
			let currentSection = '';
			sections.forEach((section) => {
				const rect = section.getBoundingClientRect();
				if (rect.top < window.innerHeight && rect.bottom >= 0) {
					currentSection = section.id;
				}
			});
			if (currentSection !== activeSection) {
				setActiveSection(currentSection);
			}
		};

		window.addEventListener('scroll', handleScroll);
		handleScroll(); // Initial check

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [activeSection]);

	const guidelines = [
		{ title: '勤務地', content: 'えがお美容室' },
		{ title: '雇用形態', content: '正社員' },
		{ title: '勤務地', content: 'えがお美容室（豊島区巣鴨3-20-14）' },
		{ title: 'アクセス', content: 'JR巣鴨駅 徒歩5分' },
		{
			title: '給与',
			content: (
				<>
					固定残業代あり:
					<br />
					月給￥250,000〜￥500,000は1か月当たりの固定
					<br />
					定残業代￥50,085〜￥110,000(45時間相当分)を含む。
					<br />
					45時間を超える残業代は追加で支給する。
					<br />
					25万円〜※経験により応相談
					<br />
					例:指名売上50万円〜のスタイリスト給与30万円〜スタート
					<br />
					指名売上がないスタイリスト給与25万円〜スタート
					<br />
					賞与:会社の業績により
					<br />
					昇給:あり
					<br />
					※みなし残業45時間含む
				</>
			),
		},
		{
			title: '待遇・福利厚生',
			content: (
				<>
					社会保険完備（雇用保険／労災保険／健康保険／厚生年金）
					<br />
					交通費全額支給
					<br />
					社員割引
				</>
			),
		},
		{ title: '企業名', content: '株式会社サンクリエーション' },
		{ title: '資格と免許', content: '美容師免許（必須）' },
	];

	return (
		<div className='flex justify-center mx-auto relative'>
			<div className='main-content w-full shadow-lg max-w-md md:max-w-[576px] rounded-2xl'>
				{/* Background Scrolling Text */}
				<div className='scroll-background'>
					<span>EGAO BEAUTY SALONS</span>
				</div>

				{/* FV Section */}
				<section className='fv '>
					<img
						src={`${apiUrl}/images/01.svg`}
						alt='30代40代美容師募集'
						className='w-full'
					/>
					<img
						id='img02'
						src={`${apiUrl}/images/02.svg`}
						alt='シニア世代を'
						className='w-full slide-in-left'
					/>
					<img
						id='img03'
						src={`${apiUrl}/images/03.svg`}
						alt='トレンドにする'
						className='w-full slide-in-right'
					/>
					<img
						src={`${apiUrl}/images/06.svg`}
						alt='あなたの技術で未来をリード'
						className='triangle'
					/>
					<img
						src={`${apiUrl}/images/05.webp`}
						alt='シニア女性画像'
						className='w-full pt-[78px]'
					/>
					<img
						src={`${apiUrl}/images/07.svg`}
						alt='フキダシ'
						className='w-full  mt-[-3rem] xss:mt-[-2.7rem] sm:mt-[-3rem] md:mt-[-4.5rem]'
					/>
				</section>
				{/* Message Section */}
				<section id='message' className='message text-center my-8 h-auto'>
					<div className='flex justify-center w-full p-4'>
						<img
							src={`${apiUrl}/images/message_1.svg`}
							alt='次へ'
							className='animate-fadeInOutDown'
						/>
					</div>
					<div className='relative'>
						<img
							src={`${apiUrl}/images/message_3.svg`}
							alt='ハサミ'
							className='absolute left-0 top-3 h-18'
						/>
						<div className='flex justify-end'>
							<div className='w-[65%] sm:w-[61%]'>
								<h2 className='text-[2rem] tracking-widest font-bold mt-8 mb-4 text-left english-text text-gradient'>
									Message
								</h2>
								<div className='underline'></div>
							</div>
						</div>
					</div>
					<p className='xss:text-base text-lg px-8 py-20 sm:py-16 md:px-28 xss:leading-10 leading-loose text-left '>
						私たちは「シニア世代」の美しさに注目しています。
						<br />
						<br />
						それは、彼女たちの「若さ」や「エネルギー」の変化に気づいている人が少なく、変革の余地が大いにあるからです。
						<br />
						<br />
						この世代のお客様一人ひとりの魅力を最大限に引き出し、彼女たち自身も気づいていない新たな美の可能性を開花させることができる、そんなプロフェッショナルを求めています。
					</p>
					<div className='relative flex justify-center w-full p-4'>
						<img
							src={`${apiUrl}/images/message_1.svg`}
							className='animate-fadeInOutDown'
							alt='次へ'
						/>
						<img
							src={`${apiUrl}/images/message_4.svg`}
							alt='ドライヤー'
							className='absolute xss:top-[-8rem] right-4 top-[-5rem] sm:top-[-7rem]'
						/>
					</div>
					<div className='w-full flex justify-center flex-col items-center gap-y-8'>
						<div className='pt-8'>
							<p className='text-xl leading-relaxed font-bold'>
								えがお美容室について
								<br />
								詳しくしりたい方
							</p>
						</div>
						<div className='xss:w-[90%] w-full flex flex-col items-center gap-y-2'>
							<img src={`${apiUrl}/images/cta_2.svg`} alt='ボタン' />
							<Link to='/contact'>
								<img
									src={`${apiUrl}/images/cta_1.svg`}
									alt='カジュアル面談'
									className='hover-image'
								/>
							</Link>
						</div>
					</div>
				</section>

				{/* About Section */}
				<section id='about' className='about text-center my-8 pt-8'>
					<div className='flex flex-col relative'>
						<img
							src={`${apiUrl}/images/about_2.webp`}
							alt='シニア画像'
							loading='lazy'
							className='w-full'
						/>
					</div>
					<div className='flex justify-center w-full p-4 mt-4'>
						<img
							src={`${apiUrl}/images/message_1.svg`}
							className='animate-fadeInOutDown'
							alt='次へ'
						/>
					</div>
					<div className='relative'>
						<img
							src={`${apiUrl}/images/message_3.svg`}
							alt='ハサミ'
							className='absolute xss:left-0 xs:left-4 xss:top-0 top-4 xss:h-28'
						/>
						<div className='w-full flex flex-col items-center justify-center'>
							<h2 className='text-[2rem] tracking-widest font-bold mt-8 mb-4 text-left english-text text-gradient'>
								About Us
							</h2>
							<h3 className='font-yu-mincho xss:text-xl text-2xl font-medium pt-8'>
								えがお美容室とは
							</h3>
						</div>
					</div>
					<div className='w-full px-8 sm:px-28'>
						<div className='underline-black'></div>
						<p className='xss:text-base text-lg xss:px-0 sm:px-0 xss:py-8 xs:py-4 py-20 sm:py-16 xss:leading-10 leading-loose text-left '>
							2018年に東京巣鴨にオープンし、シニア世代の悩みである白髪をポジティブなイメージに変化さたことをきっかけに、様々なメディアから注目を集め、この数年で急激な成長をしている東京・巣鴨の美容室です。
							<br />
							<br />
							2024年現在、2018年に比較して、顧客数は6.5倍に上昇しています。
						</p>
						<div className='underline-black'></div>
					</div>
					<div>
						<h3 className='font-yu-mincho xss:text-xl text-2xl font-medium pt-8'>
							「白髪を活かす」「白髪を隠す」
						</h3>
						<img
							src='./images/about_4.webp'
							alt='シニア画像'
							className='mt-4 w-full'
						/>
					</div>
					<div className='w-full px-8 sm:px-24 pt-8'>
						<p className='xss:text-base text-lg xss:py-8 py-20 sm:py-16 sm:px-0 xss:leading-10 leading-loose text-left'>
							えがお美容室は、「50代以上の女性のためのビューティーブランド」であるEGAOの主軸のサービスです。
							<br />
							<br />
							30名以上の各分野プロフェッショナルが、シニア世代の新しい需要を分析し、新しいサービスを開発しています。
						</p>
					</div>
					<section className='circle-container'>
						<img
							src={`${apiUrl}/images/circle_big.svg`}
							alt='Big Circle'
							className='big-circle'
						/>
						<img
							src={`${apiUrl}/images/circle_small_1.svg`}
							alt='Small Circle 1'
							className='small-circle'
						/>
						<img
							src={`${apiUrl}/images/circle_small_2.svg`}
							alt='Small Circle 2'
							className='small-circle'
						/>
						<img
							src={`${apiUrl}/images/circle_small_3.svg`}
							alt='Small Circle 3'
							className='small-circle'
						/>
						<img
							src={`${apiUrl}/images/circle_small_4.svg`}
							alt='Small Circle 4'
							className='small-circle'
						/>
						<img
							src={`${apiUrl}/images/circle_small_5.svg`}
							alt='Small Circle 5'
							className='small-circle'
						/>
						<img
							src={`${apiUrl}/images/EGAO.svg`}
							alt='EGAO Text'
							className='egaotext'
						/>
					</section>
					<img src='/images/about_5.webp' alt='EGAO' className='w-full pt-16' />
					<div className='w-full px-8 sm:px-24 pt-8'>
						<p className='xss:text-base text-lg xss:py-8 py-20 sm:py-16 sm:px-0 xss:leading-10 leading-loose text-left'>
							その技術を元に現在は、フランチャイズ「PRODUCED by
							EGAO」を作り、さらなるEGAO圏の拡大を目指しています。
						</p>
					</div>
					<img
						src={`${apiUrl}/images/about_6.svg`}
						alt='EGAO'
						className='w-full pt-16'
					/>
				</section>

				{/* Interview Section */}
				<section id='interview' className='interview text-center my-8 pt-16'>
					<div className='relative'>
						<img
							src={`${apiUrl}/images/message_3.svg`}
							alt='ハサ'
							className='absolute xss:top-[-4rem] xs:top-[-3rem] xss:right-[-1rem] xs:right-[-1.5rem] right-8 top-3 h-18'
						/>
						<div className='w-full flex flex-col items-center justify-center'>
							<h2 className='text-[2rem] tracking-widest font-bold mt-8 text-left english-text text-gradient'>
								Interview
							</h2>
							<h4 className='font-yu-mincho text-lg font-medium pt-4'>
								スタイリストインタビュー
							</h4>
						</div>
						<img
							src={`${apiUrl}/images/interview_1.webp`}
							alt='ディレクター'
							className='w-full pt-16'
						/>
					</div>
					<div className='w-full px-8 sm:px-24 pt-8'>
						<p className='xss:text-base text-lg xss:py-8 py-20 sm:py-16 sm:px-0 xss:leading-10 leading-loose text-left'>
							えがお美容室は、50代以上の大人女性に向けて、白髪を活かすためのグレイヘア、白髪を隠すためのカラーリングをともに得意としていて、女性のエイジングに伴う髪の悩みの解決しています。
							<br />
							<br />
							私たちのサービスを通して、お客様の髪の悩みを解決して、喜んでいただけるとやりがいを感じます。これに加え、広告や雑誌に掲載されるような仕事もあるため、フォトグラファーやメイクアップアーティストなど異業種の方と一緒に仕事ができることも楽しみの一つです。
						</p>
					</div>
					<img
						src={`${apiUrl}/images/interview_2.webp`}
						alt='ディレクター'
						className='w-full pt-16'
					/>
					<div className='w-full px-8 sm:px-24 pt-8'>
						<p className='xss:text-base text-lg xss:py-8 py-20 sm:py-16 sm:px-0 xss:leading-10 leading-loose text-left'>
							美容室でお客様に喜んで頂ける楽しさと普段の営業とは違うクリエイティブな仕事どちらにもとても魅力があります。
							美容師として、ヘアメイクとして得た事はお互いの仕事に良い影響を与えあい自信を成長させてくれます。
							<br />
							<br />
							自分自身が成長する事で美容という仕事を通してお客様により喜んで頂く事が出来るのでとてもやりがいを感じる事ができます。
						</p>
					</div>
				</section>

				{/* Merit Section */}
				<section id='merit' className='merit text-center my-8'>
					<div className='relative'>
						<img
							src={`${apiUrl}/images/message_3.svg`}
							alt='ハサ'
							className='absolute xs:left-0 left-8 xss:top-[-3rem] xs:top-[-3rem]  top-3 h-18'
						/>
						<div className='w-full flex flex-col items-center justify-center'>
							<h2 className='text-[2rem] tracking-widest font-bold mt-8 text-left english-text text-gradient'>
								Merit
							</h2>
							<h4 className='font-yu-mincho text-lg font-medium pt-4'>
								えがお美容室で働くメリット
							</h4>
						</div>
					</div>
					<img
						src={`${apiUrl}/images/merit_1.webp`}
						alt='ハサ'
						className='w-full pt-16'
					/>
					<div className='w-full px-8 sm:px-24 pt-8'>
						<p className='xss:text-base text-lg xss:py-8 py-20 sm:py-16 sm:px-0 xss:leading-10 leading-loose text-left'>
							シニア世代特有の髪の悩みやスタイリングニーズに対応する技術を磨くことができます。これにより、美容師としての専門性と市場価値が高まります。
						</p>
					</div>
					<img
						src={`${apiUrl}/images/merit_2.webp`}
						alt='ハサ'
						className='w-full pt-16'
					/>
					<div className='w-full px-8 sm:px-24 pt-8'>
						<p className='xss:text-base text-lg xss:py-8 py-20 sm:py-16 sm:px-0 xss:leading-10 leading-loose text-left'>
							美容師として自らのアイデアや技術を活かし、業界内で注目される新しい流行を創造できる場です。
						</p>
					</div>
					<img
						src={`${apiUrl}/images/merit_3.webp`}
						alt='ハサ'
						className='w-full pt-16'
					/>
					<div className='w-full px-8 sm:px-24 pt-8'>
						<p className='xss:text-base text-lg xss:py-8 py-20 sm:py-16 sm:px-0 xss:leading-10 leading-loose text-left'>
							企業や行政機関との連携や、商品企画・監修、そして美容師の専門知識を活かしたプロジェクトへの参画は、美容師としてのキャリアパスに幅と深みをもたらします。
						</p>
					</div>
				</section>

				{/* Guideline Section */}
				<section id='guideline' className='guideline text-center my-8'>
					<div className='bg-custom-gradient xs:p-4 p-8 text-white'>
						<h2 className='xss:text-xl text-2xl font-bold mb-4 text-center'>
							Recruitment Guidelines
						</h2>
						<div className='bg-white p-4 rounded-lg text-black'>
							<h3 className='py-4'>募集要項</h3>
							<table className='w-full'>
								<tbody className='flex flex-col items-start'>
									{guidelines.map((item, index) => (
										<tr
											key={index}
											className='flex flex-col mb-4 text-left w-full'
										>
											<td className='font-bold w-full bg-custom-gradient text-white p-2'>
												{item.title}
											</td>
											<td>{item.content}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</section>
				{/* Contact Section */}
				<section id='contact' className='contact text-center my-8'>
					<div className='w-full flex justify-center flex-col items-center gap-y-8'>
						<div className='pt-8'>
							<p className='text-xl leading-relaxed font-bold'>
								えがお美容室について
								<br />
								詳しくしりたい方
							</p>
						</div>
						<div className='xss:w-[90%] w-full flex flex-col items-center gap-y-2 pb-8'>
							<img src='./images/cta_2.svg' alt='ボタン' />
							<Link to='/contact'>
								<img
									src={`${apiUrl}/images/cta_1.svg`}
									alt='カジュアル面談'
									className='hover-image'
								/>
							</Link>
						</div>
					</div>
				</section>
			</div>
			<div className='fixed-nav'>
				<div className='nav-container'>
					<nav className='flex flex-col p-4 gap-8'>
						<p className='index'>▶INDEX</p>
						<ScrollLink
							to='message'
							smooth={true}
							duration={500}
							spy={true}
							offset={-70}
							activeClass='active'
							className={`py-2 cursor-pointer flex items-center ${
								activeSection === 'message' ? 'active' : ''
							}`}
						>
							メッセージ
						</ScrollLink>
						<ScrollLink
							to='about'
							smooth={true}
							duration={500}
							spy={true}
							offset={-100}
							activeClass='active'
							className={`py-2 cursor-pointer ${
								activeSection === 'about' ? 'active' : ''
							}`}
						>
							えがお美容室とは
						</ScrollLink>
						<ScrollLink
							to='interview'
							smooth={true}
							duration={500}
							spy={true}
							offset={-70}
							activeClass='active'
							className={`py-2 cursor-pointer ${
								activeSection === 'interview' ? 'active' : ''
							}`}
						>
							スタイリストインタビュー
						</ScrollLink>
						<ScrollLink
							to='merit'
							smooth={true}
							duration={500}
							spy={true}
							offset={-70}
							activeClass='active'
							className={`py-2 cursor-pointer ${
								activeSection === 'merit' ? 'active' : ''
							}`}
						>
							えがお美容室で働くメリット
						</ScrollLink>
						<ScrollLink
							to='guideline'
							smooth={true}
							duration={500}
							spy={true}
							offset={-70}
							activeClass='active'
							className={`py-2 cursor-pointer ${
								activeSection === 'guideline' ? 'active' : ''
							}`}
						>
							募集要項
						</ScrollLink>
					</nav>
				</div>
			</div>
		</div>
	);
};

export default Home;
