import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import '../index.css';

const Header = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const navigate = useNavigate();
	const apiUrl = process.env.REACT_APP_API_URL;

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};

	const handleLinkClick = (path) => {
		setMenuOpen(false);
		navigate(path);
	};

	return (
		<header>
			<div className='max-w-xl mx-auto flex justify-between items-center p-4'>
				<h1 className='text-2xl font-bold'>
					<Link to='/'>
						<img
							src={`${apiUrl}/images/egaologo.svg`}
							alt='EGAO'
							className='h-5 w-auto'
						/>
					</Link>
				</h1>
				<div className='md:hidden'>
					<button
						onClick={toggleMenu}
						className={`menu-btn ${menuOpen ? 'open' : ''} focus:outline-none`}
					>
						<div className='line line1'></div>
						<div className='line line2'></div>
					</button>
				</div>
			</div>
			<div className={`menu ${menuOpen ? 'open' : ''}`}>
				<button
					onClick={toggleMenu}
					className='absolute top-4 right-4 focus:outline-none'
				>
					<svg
						className='w-8 h-8'
						fill='none'
						stroke='black'
						viewBox='0 0 24 24'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							strokeWidth='1'
							d='M6 18L18 6M6 6l12 12'
						/>
					</svg>
				</button>
				<nav className='xss:px-8 flex flex-col items-center xss:gap-4'>
					<img
						src={`${apiUrl}/images/hasami.svg`}
						alt='Lines'
						className='xss:w-16 sm:w-22 my-4'
					/>
					<Link
						className='xss:text-base sm:text-2xl'
						to='/'
						onClick={() => handleLinkClick('/')}
					>
						30代・40代美容師募集
					</Link>
					<img
						src={`${apiUrl}/images/line.svg`}
						alt='Lines'
						className='w-full my-4'
					/>
					<HashLink
						className='xss:text-base sm:text-2xl'
						to='/#guideline'
						onClick={toggleMenu}
					>
						募集要項
					</HashLink>
					<img
						src={`${apiUrl}/images/line.svg`}
						alt='Lines'
						className='w-full my-4'
					/>
					<Link
						className='xss:text-base sm:text-2xl'
						to='/contact'
						onClick={() => handleLinkClick('/contact')}
					>
						カジュアル面談に応募する
					</Link>
					<img
						src={`${apiUrl}/images/chair.svg`}
						alt='Lines'
						className='xss:w-16 sm:w-22 my-4'
					/>
				</nav>
			</div>
		</header>
	);
};

export default Header;
