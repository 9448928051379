import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../index.css';

const PrivacyPolicy = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const formData = location.state;
	console.log('Received formData:', formData);

	return (
		<div className='flex justify-center mx-auto'>
			<div className='main-content w-full shadow-lg max-w-md md:max-w-[576px] rounded-2xl'>
				<div className='py-16 px-8'>
					<h1 className='text-2xl font-bold mb-4'>個人情報保護方針</h1>
					<p>
						株式会社サンクリエーション（以下「弊社」といいます。）は、お客様（以下「お客様」といいます。）の個人情報の取扱いに関する方針（以下「本方針」といいます。）を次のとおり定めます。なお、本文中の用語の定義は、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）及び関連法令によります。
					</p>
					<h2 className='text-xl font-bold mt-4'>
						1 関係法令・ガイドライン等の遵守
					</h2>
					<p>
						弊社では、個人情報保護法その他の関係法令、個人情報保護委員会の定めるガイドライン等及び本方針を遵守し、個人情報を適法かつ適正に取り扱います。
					</p>
					<h2 className='text-xl font-bold mt-4'>2 個人情報の取得</h2>
					<p>弊社は、お客様の個人情報を法かつ適正な手段により取します。</p>
					<h2 className='text-xl font-bold mt-4'>3 個人情報の利用目的</h2>
					<p>
						弊社は、お客様の個人情報について、下記の利用目的の範囲内又はその取得状況から明らかである利用目的の範囲内で利用し、ご本人の同意がある場合又は法令で認められている場合を除き、他の目的で利用しません。
					</p>
					<ul className='list-disc list-inside'>
						<li>お客様からのお問合せ、ご相談への対応</li>
						<li>
							弊社が実施するサービスにおける商品の発送、サービスの提供及びこれらに関連する業務
						</li>
						<li>
							弊社が実施するサービスにおける代金の請求、返金、支払等及びこれらに関連する事務処理
						</li>
						<li>弊社が実施するサービスにおけるサポート</li>
						<li>
							弊社が実施するサービスに関する案内・情報提供・勧誘、その他アンケート調査のお願い・連絡・回答等
						</li>
						<li>
							前各号に定める他、肖像権使用同意書において同意していただいた範囲内における顔写真、年齢情報等の利用
						</li>
					</ul>
					<h2 className='text-xl font-bold mt-4'>4 個人���ータの委託</h2>
					<p>
						弊社は、お客様の個人データの取扱いを協力会社に委託する場合があります。ただし、委託する個人データは、委託する業務を遂行するのに必要最小限の情報に限定します。
					</p>
					<h2 className='text-xl font-bold mt-4'>5 個人データの第三者提供</h2>
					<p>
						弊社は、お客様ご本人の事前同意がある場合又は法令で認められている場合を除き、お客様の個人データを第三者（委託先を除きます。）に提供いたしません。
					</p>
					<h2 className='text-xl font-bold mt-4'>6 個人データの管理</h2>
					<p>
						弊社は、不正アクセス、資料の紛失等による個人データの漏洩や、改ざん、滅失又は毀損等のリスクに対し、必要かつ適切な安全対策を実施し、その防止及び是正に努めます。
					</p>
					<h2 className='text-xl font-bold mt-4'>
						7 保有個人データに関する受付
					</h2>
					<p>
						弊社は、お客様が保有個人データについて開、訂正、利用停止等の権利をしていることを確認した場合、ご本人又は代理人からのこれらの請求に対��て、個人情報保護法が認める限度において、適切に対応します。ただし、肖像権使用同意書において同意していただいた事項と抵触する場合には、肖像権使用同意書の内容が優先します。
					</p>
					<h2 className='text-xl font-bold mt-4'>
						8 お問合せ等及び苦情処理の窓口
					</h2>
					<p>
						株式会社サンクリエーション
						<br />
						〒170-0002東京都豊島区巣鴨4-22-26 1F
						<br />
						E-mail：info@san-creation.com
					</p>
					<h2 className='text-xl font-bold mt-4'>9 本方針の改定について</h2>
					<p>
						弊社は、本方針の内容を適宜見直し、必要に応じて変更することがあります。その場合、改定版の公表の日から変更後の本方針が適用されることになります。
					</p>
					<p>
						株式会社サンクリエーション
						<br />
						平成30年5月1日制定
					</p>
					<button
						onClick={() => {
							console.log('Passing back formData:', formData);
							navigate('/contact', { state: formData });
						}}
						className='w-full bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mt-4'
					>
						戻る
					</button>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicy;
