import React from 'react';

const Footer = () => {
	return (
		<footer className='flex flex-col items-center justify-center text-xs h-24 '>
			<div className='max-w-xl container mx-auto text-center'>
				<p>&copy; 2024 Egao Beauty Salons. All rights reserved.</p>
			</div>
		</footer>
	);
};

export default Footer;
