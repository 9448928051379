import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Thanks = () => {
	const { state: formData } = useLocation();
	const navigate = useNavigate();

	const handleHomeClick = () => {
		navigate('/');
	};

	useEffect(() => {
		if (!formData) {
			navigate('/contact');
		}
	}, [formData, navigate]);

	if (!formData) {
		return null;
	}

	return (
		<div className='min-h-[92vh] flex items-center justify-center  bg-gray-100'>
			<div className='bg-white flex flex-col items-center justify-center max-w-xl w-full p-8 shadow-md rounded-2xl'>
				<h1 className='text-2xl font-bold mb-4'>送信が完了しました</h1>
				<p className='mb-4 text-center'>
					ご応募いただきありがとうございます。
					<br />
					確認のメールをお送りしました。
				</p>
				<button
					onClick={handleHomeClick}
					className='gradient-button hover:bg-blue-700 text-white font-bold py-2 px-16 mt-4 rounded-full'
				>
					ホームに戻る
				</button>
			</div>
		</div>
	);
};

export default Thanks;
