import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import API_URL from '../config';

const Confirm = () => {
	const { state: formData } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!formData) {
			navigate('/contact');
		}
	}, [formData, navigate]);

	if (!formData) {
		return null; // formDataがない場合は何も表示しない
	}

	const handleSubmit = async () => {
		try {
			// GTMのデータレイヤーにイベントをプッシュ
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'submitButtonClicked',
			});

			const response = await fetch(`${API_URL}/api/send-email`, {
				// Ensure single slash
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					to: formData.email,
					dynamicTemplateData: formData,
				}),
			});

			if (response.ok) {
				navigate('/thanks', { state: formData });
			} else {
				console.error('Failed to send email');
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handleBack = () => {
		navigate('/contact', { state: formData });
	};

	return (
		<div className='animated-gradient-bg min-h-[92vh] flex justify-center'>
			<div className='main-content max-w-xl w-full h-auto shadow-lg rounded-2xl'>
				<h1 className='text-[2rem] font-bold mt-8 mb-4 text-center english-text text-gradient'>
					Confirmation
				</h1>
				<h2 className='text-lg text-center font-semibold mb-16'>
					以下の内容で送信してよろしいですか？
				</h2>
				<div className='space-y-8 pb-8 px-24'>
					<div className='border-b border-gray-300 pb-4'>
						<p className='text-sm font-medium text-gray-700 break-words'>
							お名前: {formData.name}
						</p>
					</div>
					<div className='border-b border-gray-300 pb-4'>
						<p className='text-sm font-medium text-gray-700 break-words'>
							ふりがな: {formData.furigana}
						</p>
					</div>
					<div className='border-b border-gray-300 pb-4'>
						<p className='text-sm font-medium text-gray-700 break-words'>
							生年月日: {formData.year}年 {formData.month}月 {formData.day}日
						</p>
					</div>
					<div className='border-b border-gray-300 pb-4'>
						<p className='text-sm font-medium text-gray-700 break-words'>
							メールアドレス: {formData.email}
						</p>
					</div>
					<div className='border-b border-gray-300 pb-4'>
						<p className='text-sm font-medium text-gray-700 break-words'>
							電話番号: {formData.phone}
						</p>
					</div>
					<div className='border-b border-gray-300 pb-4'>
						<p className='text-sm font-medium text-gray-700 break-words'>
							興味のある項目: {formData.interests.join(', ')}
						</p>
					</div>
					<div className='border-b border-gray-300 pb-4'>
						<p className='text-sm font-medium text-gray-700 break-words'>
							その他興味を持った点: {formData.otherInterests}
						</p>
					</div>
					<div className='border-b border-gray-300 pb-4'>
						<p className='text-sm font-medium text-gray-700 break-words'>
							志望動機やPR: {formData.motivation}
						</p>
					</div>
					<div className='border-b border-gray-300 pb-4'>
						<p className='text-sm font-medium text-gray-700 break-words'>
							その他お問い合わせ: {formData.inquiry}
						</p>
					</div>
				</div>
				<div className='flex flex-col justify-between mt-4 px-4 pb-8'>
					<button
						onClick={handleBack}
						className='bg-gray-500 hover:bg-gray-700 w-full text-white font-bold py-2 px-4 rounded'
					>
						戻る
					</button>
					<button
						onClick={handleSubmit}
						className='gradient-button hover:bg-blue-700 w-full text-white font-bold mt-2 py-2 px-4 rounded'
					>
						送信
					</button>
				</div>
			</div>
		</div>
	);
};

export default Confirm;
