import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';

const Contact = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const initialFormData = location.state || {
		name: '',
		furigana: '',
		year: '',
		month: '',
		day: '',
		email: '',
		phone: '',
		interests: [],
		otherInterests: '',
		motivation: '',
		inquiry: '',
		privacyPolicy: false,
	};

	const [formData, setFormData] = useState(initialFormData);
	const [otherChecked, setOtherChecked] = useState(
		initialFormData.interests.includes('その他')
	);

	useEffect(() => {
		if (location.state) {
			setFormData(location.state);
			setOtherChecked(location.state.interests.includes('その他'));
		}
	}, [location.state]);

	useEffect(() => {}, [formData]);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;

		if (type === 'checkbox' && name === 'privacyPolicy') {
			setFormData({ ...formData, [name]: checked });
		} else if (type === 'checkbox') {
			const newInterests = checked
				? [...formData.interests, value]
				: formData.interests.filter((interest) => interest !== value);
			setFormData({ ...formData, interests: newInterests });

			if (value === 'その他') {
				setOtherChecked(checked);
			}
		} else {
			if (name === 'phone') {
				// 電話番号フィールドのバリデーション
				const phoneRegex = /^[0-9\b]+$/;
				if (value === '' || phoneRegex.test(value)) {
					setFormData({ ...formData, [name]: value });
				}
			} else {
				setFormData({ ...formData, [name]: value });
			}
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// サニタイズ
		const sanitizedData = {
			...formData,
			name: DOMPurify.sanitize(formData.name),
			furigana: DOMPurify.sanitize(formData.furigana),
			otherInterests: DOMPurify.sanitize(formData.otherInterests),
			motivation: DOMPurify.sanitize(formData.motivation),
			inquiry: DOMPurify.sanitize(formData.inquiry),
		};
		// 確認ページへの遷移やデータの送信処理を行う
		navigate('/confirm', { state: sanitizedData });
		console.log(sanitizedData);
	};

	return (
		<div className='flex justify-center mx-auto'>
			<div className='main-content w-full shadow-lg max-w-md md:min-w-[576px] rounded-2xl'>
				<form
					onSubmit={handleSubmit}
					className='max-w-xl mx-auto h-auto p-4 pb-8 rounded'
				>
					<h1 className='text-[2rem] tracking-widest font-bold mt-8 mb-4 text-center english-text text-gradient'>
						Contact Form
					</h1>
					<h2 className='text-lg text-center font-semibold mb-8'>
						応募フォーム
					</h2>
					<p className='text-gray-700 mb-4'>
						下記の入力欄に必要項目をご入力の上、確認ボタンを押して下さい。
						<br />
						*の箇所は必須項目です。
					</p>
					<div className='mb-4'>
						<label className='block text-gray-700' htmlFor='name'>
							氏名*
						</label>
						<input
							type='text'
							id='name'
							name='name'
							value={formData.name}
							onChange={handleChange}
							className='w-full px-3 py-2 border rounded gradient-border'
							required
						/>
					</div>
					<div className='mb-4'>
						<label className='block text-gray-700' htmlFor='furigana'>
							ふりがな
						</label>
						<input
							type='text'
							id='furigana'
							name='furigana'
							value={formData.furigana}
							onChange={handleChange}
							className='w-full px-3 py-2 border rounded gradient-border'
						/>
					</div>
					<div className='mb-4'>
						<label className='block text-gray-700' htmlFor='year'>
							生年月日*
						</label>
						<div className='flex space-x-2'>
							<input
								type='number'
								id='year'
								name='year'
								value={formData.year}
								onChange={handleChange}
								className='w-1/3 px-3 py-2 border rounded gradient-border'
								placeholder='年'
								required
							/>
							<select
								id='month'
								name='month'
								value={formData.month}
								onChange={handleChange}
								className='w-1/3 px-3 py-2 border rounded gradient-border'
								required
							>
								<option value=''>月</option>
								{[...Array(12).keys()].map((m) => (
									<option key={m + 1} value={m + 1}>
										{m + 1}
									</option>
								))}
							</select>
							<select
								id='day'
								name='day'
								value={formData.day}
								onChange={handleChange}
								className='w-1/3 px-3 py-2 border rounded gradient-border'
								required
							>
								<option value=''>日</option>
								{[...Array(31).keys()].map((d) => (
									<option key={d + 1} value={d + 1}>
										{d + 1}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className='mb-4'>
						<label className='block text-gray-700' htmlFor='email'>
							メールアドレス*
						</label>
						<input
							type='email'
							id='email'
							name='email'
							value={formData.email}
							onChange={handleChange}
							className='w-full px-3 py-2 border rounded gradient-border'
							required
						/>
					</div>
					<div className='mb-4'>
						<label className='block text-gray-700' htmlFor='phone'>
							電話番号* (数字のみ)
						</label>
						<input
							type='tel'
							id='phone'
							name='phone'
							value={formData.phone}
							onChange={handleChange}
							className='w-full px-3 py-2 border rounded gradient-border'
							required
							pattern='[0-9]*' // 数字のみを許可
						/>
					</div>
					<div className='mb-4'>
						<label className='block text-gray-700'>
							どんなところに興味を持ちましたか？*
						</label>
						<div className='ml-4'>
							<label className='block'>
								<input
									type='checkbox'
									name='interests'
									value='シニア美容'
									onChange={handleChange}
									checked={formData.interests.includes('シニア美容')}
									className='mr-2'
								/>
								シニア美容について
							</label>
							<label className='block'>
								<input
									type='checkbox'
									name='interests'
									value='技術の向上'
									onChange={handleChange}
									checked={formData.interests.includes('技術の向上')}
									className='mr-2'
								/>
								技術の向上について
							</label>
							<label className='block'>
								<input
									type='checkbox'
									name='interests'
									value='複合的な美容'
									onChange={handleChange}
									checked={formData.interests.includes('複合的な美容')}
									className='mr-2'
								/>
								複合的な美容へのアプローチについて
							</label>
							<label className='block'>
								<input
									type='checkbox'
									name='interests'
									value='その他'
									onChange={handleChange}
									checked={otherChecked}
									className='mr-2'
								/>
								その他
							</label>
						</div>
						<input
							type='text'
							id='otherInterests'
							name='otherInterests'
							value={formData.otherInterests}
							onChange={handleChange}
							className='w-full px-3 py-2 border rounded mt-2 gradient-border'
							placeholder='その他をチェックした方は興味を持った点をご記入ください'
							disabled={!otherChecked}
						/>
					</div>
					<div className='mb-4'>
						<label className='block text-gray-700' htmlFor='motivation'>
							志望動機やPR*
						</label>
						<textarea
							id='motivation'
							name='motivation'
							value={formData.motivation}
							onChange={handleChange}
							className='w-full px-3 py-2 border rounded gradient-border'
							required
							rows={5}
						></textarea>
					</div>
					<div className='mb-4'>
						<label className='block text-gray-700' htmlFor='inquiry'>
							その他お問い合わせ
						</label>
						<textarea
							id='inquiry'
							name='inquiry'
							value={formData.inquiry}
							onChange={handleChange}
							className='w-full px-3 py-2 border rounded gradient-border'
							rows={5}
						></textarea>
					</div>
					<div className='mb-4'>
						<label className='inline-flex items-center'>
							<input
								type='checkbox'
								name='privacyPolicy'
								checked={formData.privacyPolicy}
								onChange={handleChange}
								className='mr-2'
								required
							/>
							私は{' '}
							<Link
								to='/privacy-policy'
								state={formData}
								className='text-blue-500 hover:underline'
								onClick={() => console.log('Passing formData:', formData)}
							>
								プライバシーポリシー
							</Link>
							に同意します。
						</label>
					</div>
					<div className='flex flex-col w-full gap-y-4'>
						<button
							onClick={() => navigate('/')}
							className='bg-gray-500 hover:bg-gray-700 w-full text-white font-bold py-2 px-4 rounded'
						>
							戻る
						</button>

						<button
							type='submit'
							className='gradient-button hover:bg-blue-700 text-white font-bold w-full py-2 px-4 rounded-lg'
						>
							確認画面へ
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Contact;
