import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Confirm from './pages/Confirm';
import Thanks from './pages/Thanks';
import Footer from './components/Footer';
import Header from './components/Header';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ScrollToTop from './components/ScrollToTop';
import './index.css';

function App() {
	return (
		<div className='flex flex-col min-h-screen'>
			<Header />
			<main>
				<ScrollToTop />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/contact' element={<Contact />} />
					<Route path='/confirm' element={<Confirm />} />
					<Route path='/thanks' element={<Thanks />} />
					<Route path='/privacy-policy' element={<PrivacyPolicy />} />
				</Routes>
			</main>
			<Footer />
		</div>
	);
}

export default App;
